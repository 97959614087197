import { Locale } from "nextjs-routes";
// import { userSelectedLanguageIdAtom } from "../../state/user-state";
// import { getRecoil } from "recoil-nexus";

export function getLocale(): Locale {
  if (typeof window !== "undefined") {
    if (window["__LOCALE__"]) {
      return window["__LOCALE__"];
    }
  }
  return "ru";
}

export function getCurrency() {
  return "AZN";
}
