import { AggregationApiClient } from "./aggregation-client";
import { ExtendedLocationUnitDto } from "./aggregation-client/service";

export type ValidCity = Required<ExtendedLocationUnitDto>;

type Params = {
  language: string;
};

const isValidCity = (city: ExtendedLocationUnitDto): city is ValidCity => {
  return city.id != null && city.title != null;
};

export const fetchCities = async ({ language }: Params): Promise<ValidCity[]> => {
  return AggregationApiClient.LocationService.locationControllerFindAllCities({
    language,
    perPage: 99,
  })
    .then((cities) => {
      return cities.filter(isValidCity);
    })
    .catch((_err) => {
      return [];
    });
};
