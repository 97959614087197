import { Alert, Box, Button, Popover, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Popover as CustomPopover, usePopover } from "../../../fe-ui/hooks/use-popover";
import { useRecoilState, useRecoilValue } from "recoil";
import { adultsCounterAtom, childrenCounterAtom, totalGuestsCounterSelector } from "../../../state/booking-state";
import { InputCounter } from "../../../fe-ui/atoms/InputCounter";
import { useTranslation } from "../../../fe-ui/hooks/use-translation";
import DropdownIcon from "../../components/FormSearch/icons/DropdownIcon";

type Props = {
  readOnly?: boolean;
  size?: "small" | "medium" | "large";
  maxGuests?: number;
  context: "search" | "single";
  isCompact?: boolean;
  isSheet?: boolean;
  noIcon?: boolean;
};

const SearchGuestsInputBody = ({
  // popover,
  inline = false,
  onChange,
  maxGuests,
  readOnly,
}: {
  popover?: any;
  inline?: boolean;
  maxGuests?: number;
  onChange?: ({ adultsCount, childrenCount }) => void;
  readOnly?: boolean;
}) => {
  const [adultsCountSaved, saveAdultsCount] = useRecoilState(adultsCounterAtom);
  const [childrenCountSaved, saveChildrenCount] = useRecoilState(childrenCounterAtom);
  const [adultsCount, setAdultsCount] = useState(() => adultsCountSaved);
  const [childrenCount, setChildrenCount] = useState(() => childrenCountSaved);
  const t = useTranslation();
  const totalGuests = adultsCount + childrenCount;
  const isInvalid = maxGuests && totalGuests > maxGuests;

  useEffect(() => {
    if (inline) {
      saveAdultsCount(adultsCount);
      saveChildrenCount(childrenCount);
    } else {
      onChange?.({ adultsCount, childrenCount });
    }
  }, [inline, adultsCount, childrenCount, saveAdultsCount, saveChildrenCount, onChange]);

  return (
    <>
      <Stack direction={"column"} gap={"24px"}>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
          <Typography sx={{ fontWeight: 400, fontSize: "16px", lineHeight: "24px" }}>{t("vzroslye")}</Typography>
          <InputCounter
            name="adults"
            disabledDecrease={readOnly}
            disabledIncrease={readOnly}
            min={1}
            max={10}
            onChange={setAdultsCount}
            value={adultsCount}
            buttonTextColor={"text.primary"}
          />
        </Stack>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
          <Typography sx={{ fontWeight: 400, fontSize: "16px", lineHeight: "24px" }}>{t("deti")}</Typography>
          <InputCounter
            name="children"
            disabledDecrease={readOnly}
            disabledIncrease={readOnly}
            min={0}
            max={10}
            onChange={setChildrenCount}
            value={childrenCount}
            buttonTextColor={"text.primary"}
          />
        </Stack>

        {isInvalid && (
          <>
            <Alert severity="error">
              {t("errors-max-guests-exceded", {
                max: maxGuests,
              })}
            </Alert>
          </>
        )}
      </Stack>
    </>
  );
};

const SearchGuestsInputPopover = ({
  popover,
  anchorElem,
  setAnchorElem,
  maxGuests,
  readOnly,
}: {
  popover: CustomPopover;
  anchorElem: HTMLButtonElement | null;
  setAnchorElem: Dispatch<SetStateAction<HTMLButtonElement | null>>;
  maxGuests?: number;
  readOnly?: boolean;
}) => {
  const t = useTranslation();
  const [adultsCountSaved, saveAdultsCount] = useRecoilState(adultsCounterAtom);
  const [childrenCountSaved, saveChildrenCount] = useRecoilState(childrenCounterAtom);
  const [adultsCount, setAdultsCount] = useState(() => adultsCountSaved);
  const [childrenCount, setChildrenCount] = useState(() => childrenCountSaved);

  const handleSave = () => {
    saveChildrenCount(childrenCount);
    saveAdultsCount(adultsCount);
    setAnchorElem(null);
  };

  const handleChange = ({ childrenCount, adultsCount }) => {
    setChildrenCount(childrenCount);
    setAdultsCount(adultsCount);
  };

  if (!popover.open) {
    return null;
  }

  return (
    <Popover
      keepMounted={false}
      id="guest-counter-popover"
      open={!readOnly && Boolean(anchorElem)}
      onClose={handleSave}
      anchorEl={anchorElem}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      PaperProps={{
        sx: {
          minWidth: "250px",
          width: popover.open.clientWidth,
          padding: "24px",
        },
      }}
    >
      <Stack direction={"column"} sx={{}}>
        <Typography sx={{ fontWeight: 600, fontSize: "18px", lineHeight: "24px", marginBottom: "24px" }}>
          {t("Booking_guests")}
        </Typography>
        <SearchGuestsInputBody maxGuests={maxGuests} onChange={handleChange} popover={popover} />
      </Stack>
    </Popover>
  );
};

export const SearchGuestsInputForSearch = (p: Props) => {
  const { size = "large", readOnly, isCompact = false, isSheet = false } = p;
  const t = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const count = useRecoilValue(totalGuestsCounterSelector);
  const popover = usePopover();
  const [anchorElem, setAnchorElem] = useState<HTMLButtonElement | null>(null);
  const isInline = false;

  if (isInline) {
    return <SearchGuestsInputBody readOnly={readOnly} maxGuests={p.maxGuests} inline popover={popover} />;
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: isCompact ? "transparent" : isSheet ? theme.palette.background.neutral : "white",
          border: isCompact ? `1px solid ${theme.palette.divider}` : undefined,
          borderLeftWidth: isCompact ? 0 : undefined,
          ...(isSheet && { borderRadius: "8px" }),
          height: isCompact ? "62px" : isMobile || isSheet ? "56px" : "68px",
        }}
      >
        <Button
          fullWidth
          disabled={readOnly}
          onClick={(event) => {
            setAnchorElem(event.currentTarget);
            popover.onOpen(event);
          }}
          aria-owns={popover.open ? "guest-counter-popover" : undefined}
          aria-haspopup="true"
          size={size}
          color="inherit"
          data-testid="guest-amount-button"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            height: "100%",
            borderRadius: isSheet ? "8px" : 0,
            padding: isCompact
              ? "19px 12px 19px 16px"
              : isMobile || isSheet
              ? "8px 12px 8px 16px"
              : "12px 12px 12px 16px",
            whiteSpace: "nowrap",
            color: readOnly ? "#212B36 !important" : "inherit",
          }}
          disableRipple
        >
          <Stack sx={{ display: "flex", flexDirection: "col", width: "100%" }}>
            {!isCompact && (
              <Typography
                align="left"
                sx={{ color: "text.secondary", fontWeight: 600, fontSize: "12px", lineHeight: "20px" }}
              >
                {t("Booking_guests")}
              </Typography>
            )}
            <Box sx={{ display: "flex", justifyContent: "space-between", gap: "8px" }}>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: isMobile || isSheet ? "14px" : "16px",
                  lineHeight: isMobile || isSheet ? "20px" : "24px",
                }}
              >
                {t("words_guest", {
                  count,
                })}
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <DropdownIcon />
              </Box>
            </Box>
          </Stack>
        </Button>
      </Box>
      <SearchGuestsInputPopover
        readOnly={readOnly}
        maxGuests={p.maxGuests}
        popover={popover}
        anchorElem={anchorElem}
        setAnchorElem={setAnchorElem}
      />
    </>
  );
};
