export const env = (key: string, fallback: string = "") => {
  let value = undefined;
  if (typeof window !== "undefined") {
    value = window["__ENV__"]?.[key];
  } else {
    const ENV = {
      CONTENT_API_ROOT: process.env.CONTENT_API_ROOT,
      MAPBOX_API_KEY: process.env.MAPBOX_API_KEY,
      AGGREGATION_API_ROOT: process.env.AGGREGATION_API_ROOT,
      BACKEND_API_ROOT: process.env.BACKEND_API_ROOT,
    };
    value = ENV[key];
  }

  if (value !== undefined) {
    return value;
  }

  return fallback;
};
