type Props = {
  width?: number;
  height?: number;
};

export default function SearchIcon({ width = 20, height = 20 }: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3839_4308)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.75021 1.66667C7.6207 1.66676 6.5076 1.93697 5.50376 2.45475C4.49992 2.97252 3.63446 3.72285 2.97957 4.64313C2.32469 5.56341 1.89939 6.62696 1.73913 7.74504C1.57888 8.86312 1.68834 10.0033 2.05836 11.0705C2.42838 12.1377 3.04825 13.1009 3.86624 13.8798C4.68423 14.6587 5.67663 15.2306 6.76064 15.548C7.84465 15.8654 8.98883 15.9189 10.0977 15.7041C11.2066 15.4893 12.2481 15.0125 13.1352 14.3133L16.1785 17.3567C16.3357 17.5085 16.5462 17.5925 16.7647 17.5906C16.9832 17.5887 17.1922 17.501 17.3467 17.3465C17.5012 17.192 17.5889 16.983 17.5908 16.7645C17.5927 16.546 17.5087 16.3355 17.3569 16.1783L14.3135 13.135C15.1369 12.0905 15.6495 10.8353 15.7928 9.51311C15.9361 8.19088 15.7042 6.85502 15.1237 5.65841C14.5433 4.46181 13.6376 3.4528 12.5105 2.74686C11.3833 2.04092 10.0802 1.66658 8.75021 1.66667ZM3.33354 8.75C3.33354 7.31341 3.90423 5.93566 4.92005 4.91984C5.93587 3.90402 7.31362 3.33333 8.75021 3.33333C10.1868 3.33333 11.5646 3.90402 12.5804 4.91984C13.5962 5.93566 14.1669 7.31341 14.1669 8.75C14.1669 10.1866 13.5962 11.5643 12.5804 12.5802C11.5646 13.596 10.1868 14.1667 8.75021 14.1667C7.31362 14.1667 5.93587 13.596 4.92005 12.5802C3.90423 11.5643 3.33354 10.1866 3.33354 8.75Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3839_4308">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
